<template>
  <v-container>
    <h3>Evaluation Sheet</h3>
    <v-row>
      <v-col cols="12" md="3">
        <v-label
          class="pb-1"
          style="font-size: 14px; color: #272727; font-weight: 600"
          >Schedule date
          <span
            style="color: #cf0707; padding-left: 2px; font-weight: 700"
          ></span
        ></v-label>
        <DatePicker
          :placeholder="'Schedule date'"
          @selectDate="selectDate($event)"
          selectionMode="range"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-label
          class="pb-1"
          style="font-size: 14px; color: #272727; font-weight: 600"
        >
          Stage
          <span
            style="color: #cf0707; padding-left: 2px; font-weight: 700"
          ></span
        ></v-label>
        <AutoComplete
          :items="stages"
          item-title="title"
          item-value="value"
          v-model="stage"
          multiple
          placeholder="Select stage"
          @update:model-value="getSelectedCandidate"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-label
          class="pb-1"
          style="font-size: 14px; color: #272727; font-weight: 600"
        >
          Candidate
          <span
            style="color: #cf0707; padding-left: 2px; font-weight: 700"
          ></span
        ></v-label>
        <AutoComplete
          v-model="candidateId"
          :items="candidateData"
          :item-title="'displayValue'"
          :item-value="'id'"
          :placeholder="'Select Candidate Id'"
          @keyup="getAllCandidate"
          @update:model-value="getCandidateData"
        />
      </v-col>
      <v-col cols="12" md="3" v-if="candidateId">
        <v-label
          class="pb-1"
          style="font-size: 14px; color: #272727; font-weight: 600"
        >
          Candidate assessment
          <span
            style="color: #cf0707; padding-left: 2px; font-weight: 700"
          ></span
        ></v-label>
        <ListAutoComplete
          v-model="interviewId"
          :items="astEvaluation"
          item-title="AssessmentCode"
          item-value="InterviewId"
          placeholder="Select AssessmentData"
          :data="data"
          clearable
        />
      </v-col>
      <!-- <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="d-flex justify-center align-center"
        v-if="!profileData"
      >
        <v-responsive max-width="200" class="mt-15">
          <v-img
            src="../../../../assets/image/5qcj_j7sr_220705.jpg"
            class="opacity-50 rounded"
            aspect-ratio="1/2"
          ></v-img>
        </v-responsive>
      </v-col> -->

      <v-col cols="12" md="12" v-if="profileData">
        <v-card flat style="background: #efeefd" class="pa-5">
          <v-row no-wrap>
            <v-col cols="12">
              <div style="width: 100%; color: #272727; font-weight: 800">
                {{ getName }}
                with {{ profileData && (profileData.years_of_expeirence/12) }} years
                Experience
              </div>
              <small style="color: black"
                >Notice Period
                {{
                  profileData && profileData.notice_period
                    ? getNoticePeriod(profileData.notice_period)
                    : ""
                }}</small
              >
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Email
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.email }}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Mobile #
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.phone_number }}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                DOB
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && moment(profileData.dob).format('MM-DD-YYYY')}}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Assessment Date
              </div>
              <div style="width: 100%; color: #272727">
                {{
                  profileData &&
                  profileData.interview &&
                  profileData.interview.schedule_date &&
                  moment(profileData.interview.schedule_date).format("MM-DD-YYYY")
                }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="8">
            <AnswerEvaluation
              v-if="candidateId"
              :candidateId="candidateId"
              :interviewId="interviewId"
            />
          </v-col>
          <v-col cols="4">
            <Summary
              v-if="candidateId"
              :candidateId="candidateId"
              :interviewId="interviewId"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Summary from "./Summary.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import AnswerEvaluation from "./AnswerEvaluation.vue";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
import EvaluationService from "@/api/service/Evaluation/EvaluationService";
import moment from "moment";
import ListAutoComplete from "./ListAutoComplete.vue";
import DatePicker from "@/components/ui/DatePicker.vue";

export default {
  components: {
    AutoComplete,
    AnswerEvaluation,
    Summary,
    ListAutoComplete,
    DatePicker,
  },
  data() {
    return {
      data: [
        { code: "AssessmentCode" },
        { name: "AssessmentName" },
        { date: "ScheduleDate" },
        { percent: "Percentage" },
      ],
      interviewId: null,
      scheduleDate: null,
      stage: null,
      candidateData: [],
      profileData: null,
      candidateId: null,
      astEvaluation: [],
      candidateDetails: [],
      days: [
        { id: 1, day: "Immediate joining" },
        { id: 2, day: "less than 15days" },
        { id: 3, day: "less than 30days" },
        { id: 4, day: "less than 45days" },
        { id: 5, day: "less than 60days" },
        { id: 6, day: "less than 90days" },
      ],
      stages: [
        { title: "Selected", value: "Selected" },
        { title: "Rejected", value: "Rejected" },
        { title: "Hold", value: "Hold" },
        { title: "Assessment Completed", value: "assessment-completed" },
      ],
    };
  },
  computed: {
    getName() {
      return this.profileData
        ? `${this.profileData.first_name} ${this.profileData.last_name}`
        : "";
    },
    refresh() {
      return this.$store.getters.getRefreshSummary;
    },
  },
  async created() {
    await this.getAllCandidateData();
  },
  watch: {
    refresh() {
      this.getastEvaluationData(this.candidateId);
    },
    interviewId() {
      this.getCandidateData(this.candidateId);
    },
  },
  methods: {
    moment,
    async getAllCandidateData() {
      let res = await CandidateProfileService.findAll();
      this.candidateDetails = res;
    },
    getAllCandidate(event) {
      const inputValue = event.target.value;
      if (!this.scheduleDate && !this.stage) {
        this.candidateData.forEach(
          ($value) =>
            ($value.displayValue = $value.code.concat(" - ", $value.name))
        );
        this.candidateData = this.candidateDetails.filter(
          ($value) =>
            $value &&
            $value.name &&
            $value.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        if (inputValue == "") {
          this.candidateData = [];
          this.profileData = null;
        }
      }
    },
    async getastEvaluationData(id) {
      let res = await EvaluationService.getAstEvaluationData({
        candidateId: id,
      });
      this.astEvaluation = res;
    },
    getNoticePeriod(id) {
      let noticePeriod = this.days.filter((val) => val.id == id);
      return noticePeriod[0].day;
    },
    selectDate($event) {
      this.scheduleDate = $event;
      this.scheduleDate = this.scheduleDate.map(
        (date) => date && moment(date).format("YYYY-MM-DD")
      );
    },
    async getSelectedCandidate() {
      if (this.scheduleDate.length > 0 && this.stage) {
        let res = await CandidateProfileService.findSelectedCandidate({
          schedule_date: this.scheduleDate,
          stage: this.stage,
        });
        this.candidateData = res;
        this.candidateData.forEach(($value) => {
          $value.displayValue = $value.code.concat(" - ", $value.name);
        });
      }
      // let res = await CandidateProfileService.findSelectedCandidate();
      // this.candidateData = res;
      // this.candidateData.forEach($value=>{
      // $value.displayValue=$value.code.concat(' - ',$value.first_name,' ',$value.last_name)
      // })
    },
    async getCandidateData(id) {
      console.log(typeof id, "tessssssssssssssssssssssssssssssssssss");
      console.log(id != "", "dddddddddddddddddddddd");

      if (typeof id == "number") {
        var obj = { CandidateId: id };
        if (this.interviewId) {
          obj = { ...obj, scheduleId: this.interviewId };
        }
        let res = await CandidateProfileService.findScheduleDetails(obj);
        this.profileData = res;
        console.log(this.profileData, "dddddddd");
        this.getastEvaluationData(id);
      }
    },
  },
};
</script>
<style scoped>
.autoList:hover {
  background: #777777;
}
.v-field {
  cursor: text;
  background: #ececec !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px;
}
/* .v-img__img  {
    position: absolute;
    top: 30px;
    left: 220px;
    width: 50%;
    height: 30%;
    opacity: 0.7;
} */
</style>
