<template>
  <v-container>
    <v-card
      class="mx-auto overflow-auto overflow-x-hidden"
      width="72vw"
      height="93vh"
      :title="
        viewType == 'edit'
          ? 'Edit Questionnaire'
          : viewType == 'view'
          ? 'View Questionnaire'
          : 'Add Questionnaire'
      "
      style="border-radius: 12px"
    >
      <template v-slot:prepend>
        <v-img
          :src="require('../../assets/evaluation-test-svgrepo-com.svg')"
          width="30"
          height="30"
        />
      </template>
      <template v-slot:append>
        <v-btn icon="mdi-close" variant="text" @click="closeCard"></v-btn>
      </template>
      <v-form @submit.prevent="save" ref="form" v-model="value">
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              class="px-0 py-0"
              style="display: grid"
              v-if="QuestionnaireData.id"
            >
              <div class="d-flex">
                <v-radio-group
                  :disabled="showReadOnly"
                  v-model="QuestionnaireData.active"
                  inline
                >
                  <v-radio color="primary" label="Active" :value="1"></v-radio>
                  <v-radio color="error" label="Inactive" :value="0"></v-radio>
                </v-radio-group>
                <v-btn
                  icon="mdi-google-analytics"
                  variant="text"
                  @click="analysis = true"
                ></v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Question
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.title"
                :placeholder="'Name of the question'"
                :rules="questionRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Type of the Question [E]
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <EnumAutoCompleteVue
                class="dropdown"
                density="compact"
                v-model="QuestionnaireData.type_of_the_question"
                :selected="QuestionnaireData.type_of_the_question"
                :domain="'ASSESSMENT_TYPE_OF_QUES'"
                placeholder="Type of the question"
                @changeEnumData="changeQuestionType($event)"
                :showReadOnly="showReadOnly"
                :rules="typeRule"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Level [E]
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <EnumAutoCompleteVue
                class="dropdown"
                density="compact"
                v-model="QuestionnaireData.level"
                :selected="QuestionnaireData.level"
                :domain="'ASSESSMENT'"
                placeholder="Level"
                @changeEnumData="changeLevelType($event)"
                :showReadOnly="showReadOnly"
                :rules="levelRule"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Total Marks
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.total_marks"
                :placeholder="'Marks scored when correct'"
                :rules="totalMarksRule"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Negative Scoring
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.negative_scoring"
                :placeholder="'Negative score when incorrectly'"
                :rules="negativeScoringRule"
                disabled
              />
            </v-col>
            <v-col cols="12" md="12" sm="6">
              <span class="text-subtitle-1 font-weight-medium"
                >Duration to answer<span>(mins)</span></span
              >
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Minimum
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.minimum"
                :placeholder="'Minimum'"
                :rules="minimumRule"
                type="number"
                :error-messages="minErrorMessages"
                @keyup="
                  validateAndUpdateOptimum(
                    this.QuestionnaireData.minimum,
                    'min'
                  )
                "
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Maximum
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.maximum"
                :placeholder="'Maximum'"
                :rules="maximumRule"
                type="number"
                :error-messages="maxErrorMessages"
                @keyup="
                  validateAndUpdateOptimum(
                    this.QuestionnaireData.maximum,
                    'max'
                  )
                "
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Optimum
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.optimum"
                :placeholder="'Optimum'"
                type="number"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Type of the answer
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="QuestionnaireData.type_of_the_answer"
                :placeholder="'Text'"
                :rules="typeRule"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Category
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                v-model="QuestionnaireData.category_id"
                :items="assessmentCategoryData"
                item-value="id"
                item-title="name"
                :placeholder="'Select Category'"
                :rules="categoryRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="1">
                  <uploadFiles
                    style="width: 14px"
                    @documentsData="documentsData($event, 'qes', null)"
                    :keyType="'UP'"
                    :type="'imgage'"
                    :profile="true"
                    :fileForUpload="fileForUpload"
                    :inputType="InputType"
                    :disabled="showReadOnly"
                  />
                </v-col>
                <v-col cols="12" md="11">
                  <v-chip
                    density="compact"
                    variant="flat"
                    color="#BBDEFB"
                    v-if="
                      QuestionnaireData &&
                      QuestionnaireData.question_img_url &&
                      QuestionnaireData.question_img_url.img_name
                    "
                    closable
                    :disabled="showReadOnly"
                    @click:close="removeChip(QuestionnaireData, 'qsChip')"
                  >
                    {{
                      QuestionnaireData.question_img_url &&
                      QuestionnaireData.question_img_url.img_name
                        ? QuestionnaireData.question_img_url.img_name
                        : ""
                    }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <template v-if="disabled">
              <v-col cols="12" md="12" sm="12" class="mt-4 mb-2">
                <span class="text-subtitle-1 font-weight-medium">Options</span>
                <v-btn
                  prepend-icon="mdi-plus"
                  class="text-none text-button float-right"
                  color="#3C5AAA"
                  variant="text"
                  text="Add"
                  @click="addOption"
                  v-if="QuestionnaireData.type_of_the_question !== 'yes/no'"
                  :disabled="showReadOnly"
                ></v-btn>
              </v-col>

              <v-col
                v-for="(option, index) in options"
                :key="index"
                cols="12"
                class="my-2 mt-n4"
              >
                <v-row>
                  <v-col cols="12">
                    <v-label
                      class="pb-1"
                      style="font-size: 14px; color: #272727; font-weight: 600"
                      >Option {{ index + 1 }}
                    </v-label>
                    <TextFieldComponent
                      v-model="option.text"
                      :placeholder="'Question option'"
                      :rules="optionRule"
                      @keyup="updateOption(option.text, index)"
                      :disabled="
                        QuestionnaireData.type_of_the_question == 'yes/no' ||
                        showReadOnly
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    lg="6"
                    xs="12"
                    :class="
                      QuestionnaireData.type_of_the_question !== 'draganddrop'
                        ? 'mt-n6 mb-n4 d-flex ga-4'
                        : 'mt-n6 mb-4'
                    "
                  >
                    <v-row>
                      <v-col cols="12" md="1">
                        <uploadFiles
                          @documentsData="documentsData($event, null, index)"
                          :type="'imgage'"
                          :keyType="'UP'"
                          :profile="true"
                          :fileForUpload="fileForUpload"
                          :inputType="InputType"
                          :disabled="showReadOnly"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-chip
                          density="compact"
                          variant="flat"
                          v-if="option && option.img_name"
                          closable
                          :disabled="showReadOnly"
                          @click:close="removeChip(option, 'optChip')"
                          class="overflow-auto overflow-y-hidden"
                          color="#BBDEFB"
                        >
                          {{ option && option.img_name ? option.img_name : "" }}
                        </v-chip>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-checkbox
                          style="position: relative; left: 30px; bottom: 8px"
                          class="text-body-2 font-weight-medium"
                          v-model="option.correct"
                          color="primary"
                          :value="index"
                          :disabled="
                            !isCorrectAnswerSelectionAllowed || showReadOnly
                          "
                          label="Correct Answer"
                          @change="updateCorrectAnswers(index)"
                          v-if="
                            QuestionnaireData.type_of_the_question !==
                            'draganddrop'
                          "
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <v-col
              v-if="QuestionnaireData.type_of_the_question === 'draganddrop'"
            >
              <div class="text-subtitle-1 font-weight-medium">
                Correct Order
              </div>
              <div class="main-item" :disabled="showReadOnly">
                <div
                  v-for="(item, index) in dragOrder"
                  :key="item.id"
                  class="item"
                  draggable="true"
                  @dragstart="onDragStart(index)"
                  @dragover.prevent
                  @drop="onDrop(index)"
                >
                  <span style="font-weight: bold" :disabled="showReadOnly">{{
                    item
                  }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" v-if="alreadyExists">
              <v-error
                >Question is already exist in Questionnaire (Type of question &
                Level are same)</v-error
              >
            </v-col>
            <v-col cols="12" v-if="text">
              <v-error>{{ text }}</v-error>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="closeCard">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- <v-snackbar color="red-lighten-5" v-model="snackbar" :timeout="timeout">
      {{ text }}
    </v-snackbar> -->
    <v-dialog v-model="analysis" width="990" class='mt-5'>
      <Analytics @close="closeDialog" :id="id" />
    </v-dialog>
  </v-container>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService";
import AssessmentCategoryService from "@/api/service/AssessmentCategory/AssessmentCategoryService";
import uploadFiles from "@/components/uploadFiles.vue";
import documentService from "@/api/service/Document/DocumentService";
import Analytics from "./analytics.vue";

export default {
  components: {
    TextFieldComponent,
    AutoComplete,
    EnumAutoCompleteVue,
    uploadFiles,
    Analytics,
  },
  props: ["id", "viewType"],

  data() {
    return {
      QuestionnaireData: {
        total_marks: 5,
        negative_scoring: "No",
        type_of_the_question: null,
        active: 1,
        options: [],
        correct_answers: [],
      },
      analysis: false,
      alreadyExists: false,
      fileForUpload: null,
      InputType: null,
      attachmentId: [],
      chipIndex: null,
      attachmentData: [],
      docName: null,
      showReadOnly: false,
      questionRule: [(value) => !!value || "Question is Required."],
      typeRule: [(value) => !!value || "Type is Required."],
      levelRule: [(value) => !!value || "Level is Required."],
      totalMarksRule: [(value) => !!value || "Total Marks is Required."],
      negativeScoringRule: [
        (value) => !!value || "Negative Scoring is Required.",
      ],
      minimumRule: [(value) => !!value || "Minimum is Required."],
      maximumRule: [(value) => !!value || "Maximum is Required."],
      optimumRule: [(value) => !!value || "Optimum is Required."],
      categoryRule: [(value) => !!value || "Category is Required."],
      optionRule: [(value) => !!value || "Option is Required."],
      // snackbar: false,
      timeout: 4000,
      disabled: true,
      options: [
        { sequence: "1", text: null, imageId: null },
        { sequence: "2", text: null, imageId: null },
      ],
      typeOfTheAnswer: ["Text"],
      negativeScoring: ["YES", "NO"],
      assessmentCategoryData: [],
      text: null,
      dragOrder: [],
      draggedIndex: null,
      questionType: null,
      duration: false,
      shwimg: false,
      shwimgQes: false,
      errorDuration: null,
    };
  },
  created() {
    this.getAssessmentCategoryData();
    if (this.id) {
      this.getQuestionnaireData(this.id);
    }
    if (this.viewType) {
      this.showReadOnly =
        this.viewType == "edit" || this.viewType == "add" ? false : true;
      this.QuestionnaireData.type_of_the_question == "freeform" &&
        (this.disabled = false);
    }
  },
  computed: {
    minErrorMessages() {
      if (
        this.QuestionnaireData.minimum &&
        this.QuestionnaireData.maximum &&
        parseFloat(this.QuestionnaireData.minimum) >=
          parseFloat(this.QuestionnaireData.maximum)
      ) {
        return ["Minimum value cannot be greater than maximum value"];
      }
      return [];
    },
    maxErrorMessages() {
      if (
        this.QuestionnaireData.maximum &&
        this.QuestionnaireData.minimum &&
        parseFloat(this.QuestionnaireData.maximum) <=
          parseFloat(this.QuestionnaireData.minimum)
      ) {
        return ["Maximum value cannot be less than minimum value"];
      }
      return [];
    },
    isCorrectAnswerSelectionAllowed() {
      const questionType = this.QuestionnaireData.type_of_the_question;
      return (
        questionType === "multiplechoice" ||
        questionType === "anyone" ||
        questionType === "freeform" ||
        questionType === "draganddrop" ||
        questionType === "yes/no"
      );
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.QuestionnaireData = {};
        this.getQuestionnaireData(this.id);
      }
    },
  },
  methods: {
    closeDialog() {
      this.analysis = false;
    },
    removeChip($data, $type) {
      if ($type == "optChip") {
        $data.img_name = null;
        $data.img_url = null;
      } else {
        $data.question_img_url = {};
      }
    },
    async documentsData($event, type, index) {
      this.attachmentId.push($event.id);
      if (type == "qes") {
        this.QuestionnaireData.question_img_url = {
          img_name: $event.doc_name,
          img_url: $event.url,
        };
        console.log(this.QuestionnaireData.question_img_url, "eeee");
      } else {
        this.options[index].img_url = $event.url;
        this.options[index].img_name = $event.doc_name;
        this.shwimg = true;
        console.log(this.options, "eeee", this.shwimg, this.chipIndex);
      }
      await this.getAttachementData();
    },
    async getAttachementData() {
      if (this.attachmentId.length > 0) {
        try {
          let res = await documentService.getAllDocuments({
            doc_id: JSON.stringify(this.attachmentId),
          });
          this.attachmentData = res;
          this.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      }
    },
    validateAndUpdateOptimum(value, type) {
      if (type == "min") {
        this.QuestionnaireData.minimum = value;
      } else {
        this.QuestionnaireData.maximum = value;
      }
      this.QuestionnaireData.optimum =
        (parseFloat(this.QuestionnaireData.minimum) +
          parseFloat(this.QuestionnaireData.maximum)) /
        2;
    },
    closeCard() {
      this.$emit("closeCard", false);
    },
    sortBackupCategoryData($data) {
      $data.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
    changeLevelType($event) {
      this.QuestionnaireData.level = $event;
    },
    changeQuestionType($event) {
      console.log($event, "bbbbbbbbbb");
      this.QuestionnaireData.type_of_the_question = $event;
      if (
        this.QuestionnaireData.type_of_the_question &&
        this.QuestionnaireData.type_of_the_question == "draganddrop"
      ) {
        this.getReadyData(null, "start");
      }
      this.updateCorrectAnswers();

      this.updateOption();
    },
    getReadyData(index, type) {
      if (type == "start") {
        for (let item in this.options) {
          this.dragOrder.push(parseInt(item) + 1);
          this.dragOrder = this.dragOrder.filter((value, index, array) => {
            return array.indexOf(value) == index;
          });
        }
      } else {
        this.dragOrder.push(index);
      }
    },
    async getQuestionnaireData(id) {
      let res = await QuestionnaireService.findOne({ id: id });
      res = JSON.parse(JSON.stringify(res));
      if (res && res.length > 0) {
        if (res[0].options) {
          res[0].options = JSON.parse(res[0].options);
        }
        this.options = res[0].options;
        this.QuestionnaireData = res[0];
        this.QuestionnaireData.question_img_url = this.QuestionnaireData
          .question_img_url
          ? JSON.parse(this.QuestionnaireData.question_img_url)
          : "";

        console.log(this.QuestionnaireData, "fff");
        if (
          this.QuestionnaireData.type_of_the_question &&
          this.QuestionnaireData.type_of_the_question == "draganddrop"
        ) {
          this.dragOrder = this.QuestionnaireData.correct_answers
            ? JSON.parse(this.QuestionnaireData.correct_answers)
            : [];
        } else {
          this.QuestionnaireData.correct_answers = this.QuestionnaireData
            .correct_answers
            ? JSON.parse(this.QuestionnaireData.correct_answers)
            : [];
        }
        console.log(this.QuestionnaireData, "hhhwwwwweeeee");
      }
    },
    addOption() {
      const newIndex = this.options.length + 1;
      if (this.QuestionnaireData.type_of_the_question === "yes/no") {
        return;
      }
      this.options.push({
        sequence: newIndex.toString(),
        text: null,
        imageId: null,
      });
      if (
        this.QuestionnaireData.type_of_the_question &&
        this.QuestionnaireData.type_of_the_question == "draganddrop"
      ) {
        this.getReadyData(newIndex, "add");
      }
    },
    updateOption(text, index) {
      if (this.QuestionnaireData.type_of_the_question == "yes/no") {
        this.options.forEach((opt, i) => (opt.text = i === 0 ? "Yes" : "No"));
      } else {
        if (
          this.questionType &&
          this.questionType !== this.QuestionnaireData.type_of_the_question
        ) {
          this.options.forEach((opt) => {
            opt.text = null;
          });
        } else if (index >= 0 && index < this.options.length) {
          this.options[index].text = text;
        }
      }
      this.questionType = this.QuestionnaireData.type_of_the_question;
    },
    updateCorrectAnswers(index) {
      if (this.QuestionnaireData.type_of_the_question === "anyone") {
        this.options.forEach((opt, i) => {
          if (i !== index) {
            opt.correct = false;
          }
        });
        this.disabled = true;
        if (!this.QuestionnaireData) {
          this.options.splice(2, 1);
        }
        this.QuestionnaireData.type_of_the_answer = "Text";
        this.QuestionnaireData.correct_answers = [this.options[index]];
        this.QuestionnaireData.correct_answers =
          this.QuestionnaireData.correct_answers.filter((el) => el != null);
        let spliceIndex = this.QuestionnaireData.correct_answers.findIndex(
          (crt) => typeof crt.correct == "boolean"
        );
        if (spliceIndex > -1) {
          this.QuestionnaireData.correct_answers.splice(spliceIndex, 1);
        }
      } else if (
        this.QuestionnaireData.type_of_the_question === "multiplechoice"
      ) {
        this.disabled = true;
        if (!this.QuestionnaireData) {
          this.options.splice(2, 1);
        }
        this.QuestionnaireData.type_of_the_answer = "Text";
        this.QuestionnaireData.correct_answers.push(this.options[index]);
        this.QuestionnaireData.correct_answers =
          this.QuestionnaireData.correct_answers.filter((el) => el != null);
        let findSeq = this.QuestionnaireData.correct_answers.find(
          (crt) => typeof crt.correct == "boolean"
        );
        this.QuestionnaireData.correct_answers =
          this.QuestionnaireData.correct_answers.filter((v, i, s) => {
            return (
              i ===
              s.findIndex(
                (t) => t.sequence === v.sequence && t.correct !== false
              )
            );
          });
        if (findSeq !== undefined) {
          this.QuestionnaireData.correct_answers =
            this.QuestionnaireData.correct_answers.filter(
              (v) => v.sequence !== findSeq.sequence
            );
        }
      } else if (this.QuestionnaireData.type_of_the_question === "freeform") {
        this.disabled = false;
        this.QuestionnaireData.type_of_the_answer = "Text";
      } else if (this.QuestionnaireData.type_of_the_question === "yes/no") {
        this.options.forEach((opt, i) => {
          if (i !== index) {
            opt.correct = false;
          }
        });
        this.disabled = true;
        this.options.splice(2, 1);
        this.options.forEach((opt, i) => (opt.text = i === 0 ? "Yes" : "No"));
        this.QuestionnaireData.type_of_the_answer = "Text";
        this.QuestionnaireData.correct_answers.push(this.options[index]);
        this.QuestionnaireData.correct_answers =
          this.QuestionnaireData.correct_answers.filter((el) => el != null);
      } else if (
        this.QuestionnaireData.type_of_the_question === "draganddrop"
      ) {
        this.disabled = true;
        if (!this.QuestionnaireData) {
          this.options.splice(2, 1);
        }
        this.QuestionnaireData.type_of_the_answer = "Text";
      }
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        console.log("Error");
      } else {
        if (
          (this.QuestionnaireData.type_of_the_question === "multiplechoice" &&
            this.options.length < 4) ||
          (this.QuestionnaireData.type_of_the_question === "anyone" &&
            this.options.length < 4)
        ) {
          // this.snackbar = true;
          this.text = "Please add at least 4 options for questions.";
          return;
        } else if (
          (this.QuestionnaireData.type_of_the_question === "anyone" &&
            this.QuestionnaireData.correct_answers.length == 0) ||
          (this.QuestionnaireData.type_of_the_question === "yes/no" &&
            this.QuestionnaireData.correct_answers.length == 0)
        ) {
          // this.snackbar = true;
          this.text = "Please choose the correct answer";
          return;
        } else if (
          this.QuestionnaireData.type_of_the_question === "multiplechoice" &&
          this.QuestionnaireData.correct_answers.length < 2
        ) {
          // this.snackbar = true;
          this.text = "Please choose atleast two correct answer";
          return;
        } else {
          if (
            this.QuestionnaireData.type_of_the_question &&
            this.QuestionnaireData.type_of_the_question !== "freeform"
          ) {
            this.QuestionnaireData.options = JSON.stringify(this.options);
          } else {
            this.QuestionnaireData.options = JSON.stringify([]);
          }
          this.QuestionnaireData.correct_answers = JSON.stringify(
            this.QuestionnaireData.correct_answers
          );
          this.QuestionnaireData.question_img_url = JSON.stringify(
            this.QuestionnaireData.question_img_url
          );

          if (
            this.QuestionnaireData.type_of_the_question &&
            this.QuestionnaireData.type_of_the_question == "draganddrop"
          ) {
            this.QuestionnaireData.correct_answers = JSON.stringify(
              this.dragOrder
            );
          }
          if (this.QuestionnaireData.id) {
            let output = await QuestionnaireService.update(
              this.QuestionnaireData
            );
            console.log(this.QuestionnaireData, "output", output);
            if (
              output &&
              output.data &&
              output.data.data &&
              output.data.data.message &&
              output.data.data.message == "Already exists"
            ) {
              this.alreadyExists = true;
            } else {
              this.alreadyExists = false;
              this.closeCard();
              this.$emit("reload", true);
            }
          } else {
            let output = await QuestionnaireService.create(
              this.QuestionnaireData
            );
            console.log(this.QuestionnaireData, "output", output);
            if (
              output &&
              output.data &&
              output.data.data &&
              output.data.data.message &&
              output.data.data.message == "Already exists"
            ) {
              this.alreadyExists = true;
            } else {
              this.alreadyExists = false;
              this.closeCard();
              this.$emit("reload", true);
              this.QuestionnaireData = {
                options: [],
                correct_answers: [],
                imageId: [],
              };
              this.options = [
                { sequence: "1", text: null, imageId: null },
                { sequence: "2", text: null, imageId: null },
              ];
            }
          }
        }
      }
    },
    async getAssessmentCategoryData() {
      let res = await AssessmentCategoryService.findAll();
      this.assessmentCategoryData = JSON.parse(JSON.stringify(res));
      this.sortBackupCategoryData(this.assessmentCategoryData);
    },
    onDragStart(index) {
      this.draggedIndex = index;
    },
    onDrop(index) {
      const draggedItem = this.dragOrder.splice(this.draggedIndex, 1)[0];
      this.dragOrder.splice(index, 0, draggedItem);
      this.draggedIndex = null;
    },
  },
};
</script>
<style scoped>
.v-input--density-default {
  --v-input-control-height: 15px;
}
.chip-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.v-chip[draggable="true"] {
  cursor: move;
}

.v-chip.dragging {
  opacity: 0.5;
}

.item {
  display: flex;
  padding: 5px 10px 5px 10px;
  flex-direction: row;
  width: fit-content;
  margin: 5px 8px;
  background-color: #d9d9d9;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: move;
}

.main-item {
  display: flex;
  padding: 10px;
  flex-direction: row;
}
</style>
